.ZzgSFq_product-device {
  background-color: #333;
  border-radius: 21px;
  width: 300px;
  height: 540px;
  position: absolute;
  bottom: -30%;
  right: 10%;
  transform: rotate(30deg);
}

.ZzgSFq_product-device:before {
  content: "";
  background-color: #ffffff1a;
  border-radius: 5px;
  position: absolute;
  inset: 10% 10px;
}

.ZzgSFq_product-device-2 {
  background-color: #e5e5e5;
  inset: -25% auto 0 5%;
}

@media (width >= 576px) {
  .ZzgSFq_featurette-divider {
    margin: 5rem 0 !important;
  }
}

.ZzgSFq_featurette-heading {
  letter-spacing: -.05rem;
  font-weight: 300;
  line-height: 1;
}

@media (width >= 40em) {
  .ZzgSFq_featurette-heading {
    font-size: 50px;
  }
}

@media (width <= 767px) {
  .ZzgSFq_featurette-logo {
    width: 50%;
    margin: auto;
    display: block;
  }
}

.IiZpua_github-box {
  color: #666;
  background: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-family: helvetica, arial, sans-serif;
  font-size: 13px;
  line-height: 18px;
}

.IiZpua_github-box a {
  color: #4183c4;
  border: none;
}

.IiZpua_github-box-title {
  background: linear-gradient(#fcfcfc, #ebebeb);
  border-bottom: 1px solid #ddd;
  border-radius: 3px 3px 0 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.IiZpua_github-box-title h3 {
  color: gray;
  margin: 0;
  font-family: helvetica, arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
}

.IiZpua_github-box-title h3 > * {
  vertical-align: middle;
  display: inline-block;
}

.IiZpua_github-stats {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin: auto .5rem;
  font-size: 11px;
  font-weight: bold;
}

.IiZpua_github-stats a {
  vertical-align: middle;
  color: #666;
  background-repeat: no-repeat;
  padding: 0 5px 0 18px;
  display: inline-block;
}

.IiZpua_github-stats .IiZpua_watchers {
  border-right: 1px solid #ddd;
}

.IiZpua_github-stats .IiZpua_forks {
  background-position: -4px -21px;
  padding-left: 15px;
}

.IiZpua_github-box-content {
  padding: 10px;
  font-weight: 300;
}

.IiZpua_github-box-download {
  background: #fff;
  border-top: 1px solid #ddd;
  border-radius: 0 0 3px 3px;
  justify-content: space-between;
  padding: 10px;
  display: flex;
}

.IiZpua_github-box-download .IiZpua_updated {
  color: #666;
  margin: 0;
  font-size: 11px;
  font-weight: 300;
}

.IiZpua_github-box-download .IiZpua_download {
  color: #666;
  text-shadow: 0 1px #ffffffe6;
  background: linear-gradient(#f5f5f5, #e5e5e5);
  border: 1px solid #ddd;
  border-bottom-color: #bbb;
  border-radius: 3px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
}

.IiZpua_github-box-download .IiZpua_download:hover {
  color: #527894;
  background: linear-gradient(#f1f7fa, #dbeaf1);
  border-color: #cfe3ed #cfe3ed #9fc7db;
}

.CPtZdW_logo {
  max-width: 1.5em;
  max-height: 1.5em;
}

.CPtZdW_logo.CPtZdW_big {
  font-size: 3rem;
}

.BPWP7q_gh-profile-card {
  background: #fafafa;
  border: 1px solid #ddd;
  border-bottom-width: 2px;
  border-radius: 5px;
  width: 280px;
  margin: auto;
  font-family: Helvetica;
  font-size: 16px;
  overflow: hidden;
}

.BPWP7q_gh-profile-card a {
  color: #444;
  text-decoration: none;
}

.BPWP7q_gh-profile-card a:hover {
  color: #4183c4;
}

.BPWP7q_gh-profile-card > header {
  background: #fff;
  padding: 15px 10px 0;
  overflow: hidden;
}

.BPWP7q_profile-name {
  color: #222;
  font-size: 1.2em;
  font-weight: bold;
  display: block;
}

.BPWP7q_profile-languages {
  clear: both;
  border-top: 1px solid #dedede;
  margin: 0 -10px;
  padding: 10px;
  font-size: .8em;
  position: relative;
}

.BPWP7q_profile-languages:before {
  content: "Top languages";
  color: #555;
  background: #fff;
  padding-right: 5px;
  font-style: italic;
  position: absolute;
  top: -.7em;
}

.BPWP7q_profile-languages > li {
  color: #444;
  margin-left: 10px;
  font-weight: bold;
  display: inline-block;
}

.BPWP7q_profile-languages > li:after {
  content: "•";
  color: #999;
  margin-left: 10px;
}

.BPWP7q_profile-languages > li:last-child:after {
  content: "";
}

.BPWP7q_profile-follow {
  color: #333;
  white-space: nowrap;
  text-shadow: 0 1px #ffffffe6;
  -webkit-user-select: none;
  user-select: none;
  background: linear-gradient(#fafafa, #eaeaea);
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  padding: 0 10px;
  font-size: .8em;
  font-weight: bold;
  line-height: 1.5em;
}

.BPWP7q_profile-follow:hover {
  color: inherit;
  background: linear-gradient(#eee, #ddd);
}

.BPWP7q_profile-followers {
  color: #444;
  background: #fff;
  border: 1px solid #bbb;
  margin-left: 8px;
  padding: 0 5px;
  font-size: .8em;
  position: relative;
}

.BPWP7q_profile-followers:before {
  content: "";
  background: inherit;
  border-top: 1px solid;
  border-left: 1px solid;
  border-color: inherit;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 30%;
  left: -4px;
  transform: rotate(-45deg);
}

.BPWP7q_profile-avatar {
  border-radius: 5px;
  width: 64px;
  height: 64px;
  margin: 0 10px 15px 0;
  box-shadow: 0 0 2px #ddd;
}

.BPWP7q_repos-header {
  color: #555;
  background: linear-gradient(#fafafa, #eaeaea);
  border: 1px solid #d5d5d5;
  border-width: 1px 0;
  padding: 5px 10px;
  font-size: .8em;
  font-weight: bold;
}

.BPWP7q_profile-repos {
  border-top: 1px solid #ddd;
  padding: 7px 10px;
  font-size: .9em;
  display: block;
}

.BPWP7q_profile-repos:first-of-type {
  border: none;
}

.BPWP7q_repos-name {
  text-overflow: ellipsis;
  max-width: 280px;
  font-weight: bold;
}

.BPWP7q_repos-updated {
  color: #777;
  font-size: .75em;
  font-style: italic;
}

.BPWP7q_repos-star {
  color: #888;
  font-size: .9em;
}

.BPWP7q_repos-star:after {
  content: " ★";
  font-size: 1.1em;
  font-weight: bold;
}
/*# sourceMappingURL=index.002765b3.css.map */
